var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-4",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mx-2 mb-4 text-h6 font-weight-black align-center",attrs:{"no-gutters":""}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-arrow-down-thick")]),_vm._v(" Depósitos ")],1),_c('sub-navigation',{staticClass:"mb-4",attrs:{"routes":_vm.routes}}),_c('Filters',{staticClass:"mb-2",attrs:{"dataFilters":require('@/assets/filters/deposit.json')},on:{"apply-filter":_vm.applyFilter}}),_c('section',[_c('v-row',{staticClass:"pa-3"},[_c('v-chip-group',{staticClass:"mb-2",attrs:{"column":""},on:{"change":function($event){return _vm.getData()}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},_vm._l((_vm.statusFilter),function(item,i){return _c('v-chip',{key:i,staticClass:"mr-1",attrs:{"color":"transparent","active-class":((item.color) + "--text"),"value":item.value,"small":""}},[_c('v-icon',{attrs:{"color":item.color,"left":"","small":""}},[_vm._v(" mdi-square-rounded")]),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.label)+" ")])],1)}),1),_c('v-spacer'),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{staticClass:"align-self-center ma-3",attrs:{"color":"grey","loading":_vm.fetching,"disabled":_vm.fetching,"small":"","icon":""},on:{"click":function($event){return _vm.getData()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-refresh")])],1):_vm._e()],1),_c('v-card',{staticClass:"rounded-lg",attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"accent pointer-hand",attrs:{"headers":_vm.headers,"items":_vm.transactions,"items-per-page":_vm.itemsPerPage,"mobile-breakpoint":0,"item-class":_vm.rowClasses,"custom-sort":_vm.customSort,"hide-default-footer":""},on:{"dblclick:row":_vm.handleTransaction},scopedSlots:_vm._u([{key:"item.orderId",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start align-center"},[_c('span',{domProps:{"textContent":_vm._s(item.orderId)}}),(
                item.logs &&
                  item.logs.length &&
                  _vm.getPixLogStatusCounts(item)['notAwaiting']
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"ms-2 mb-4",style:({ border: '1px solid red' }),attrs:{"size":"20px"},on:{"click":function($event){return _vm.handleTransaction(null, { item: item, tab: 2 })}}},'v-avatar',attrs,false),on),[_c('span',{staticClass:"primary_text--text",style:({ fontSize: '10px' }),domProps:{"textContent":_vm._s(("+" + (_vm.getPixLogStatusCounts(item)['notAwaiting'])))}})])]}}],null,true)},[_c('span',{staticClass:"caption",domProps:{"textContent":_vm._s(_vm.getPixLogAdditionalPaymentsText(item))}})]):_vm._e()],1)]}},{key:"item.paymentMethod",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getMethod(item.paymentMethod)))])]}},{key:"item.user",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"pt-1"},[_c('p',{staticClass:"mb-0 single-line"},[_vm._v(" "+_vm._s(item.userName)+" ")]),_c('p',{staticClass:"mb-0 text-caption single-line"},[_vm._v(_vm._s(item.param1))])])]}},{key:"item.email",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"pt-1"},[_c('p',{staticClass:"mb-0 single-line",style:(("max-width: " + (_vm.$vuetify.breakpoint.mdAndUp ? '14vw' : '48vw')))},[_vm._v(" "+_vm._s(item.email)+" ")]),_c('p',{staticClass:"mb-0 text-caption single-line"},[_vm._v(_vm._s(item.document))])])]}},{key:"item.convertedValue",fn:function(ref){
              var item = ref.item;
return [_vm._v(" R$ "+_vm._s(_vm.moneyMask(item.convertedValue))+" ")]}},{key:"item.createdAt",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.updatedAt))+" ")]}},{key:"item.requestStatus",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorByRequestStatus(item.requestStatus)}},[_vm._v(" "+_vm._s(item.requestStatus || "- -")+" ")])]}},{key:"item.credited",fn:function(ref){
              var item = ref.item;
return [([0, 1, 2, 3].includes(item.credited))?_c('v-icon',{attrs:{"color":_vm.creditedList[item.credited].color,"title":_vm.creditedList[item.credited].label}},[_vm._v(" "+_vm._s(_vm.creditedList[item.credited].icon)+" ")]):_c('v-chip',{attrs:{"color":"grey"}},[_vm._v(" "+_vm._s("- -")+" ")])]}},{key:"item.additional",fn:function(ref){
              var item = ref.item;
return [(item.card)?_c('span',[_c('p',{staticClass:"mb-0 single-line"},[_vm._v(" "+_vm._s(item.card.number)+" ")]),_c('p',{staticClass:"mb-0 text-caption"},[_vm._v("Cartão")])]):(item.pix)?_c('span',[_c('p',{staticClass:"mb-0 single-line"},[_vm._v(" "+_vm._s(item.pix.document)+" ")]),_c('p',{staticClass:"mb-0 text-caption"},[_vm._v("Documento PIX")])]):(item.boleto)?_c('span',[_c('p',{staticClass:"mb-0 single-line"},[_vm._v(" "+_vm._s(item.boleto.ourNumber)+" ")]),_c('p',{staticClass:"mb-0 text-caption"},[_vm._v("Nosso número")])]):(item.transfer)?_c('span',[_c('p',{staticClass:"mb-0 single-line"},[_vm._v(" R$ "+_vm._s(_vm.moneyMask(item.transfer.transferValue))+" ")]),_c('p',{staticClass:"mb-0 text-caption"},[_vm._v("Transferência")])]):(item.picpay)?_c('span',[_c('p',{staticClass:"mb-0 single-line"},[_vm._v(" "+_vm._s(item.picpay.username)+" ")]),_c('p',{staticClass:"mb-0 text-caption"},[_vm._v("Usuário PicPay")])]):_c('span',[_vm._v(" ~ ")])]}},{key:"item.receipt",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"color":"grey darken-4","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":_vm.analystReceipt(item) ? 'secondary' : 'grey',"small":""},on:{"click":function($event){return _vm.analystReceipt(item, true)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-receipt ")])]}}],null,true)},[_c('span',[_vm._v("Comprovante do analista")])]),_c('v-tooltip',{attrs:{"color":"grey darken-4","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":_vm.customerReceipt(item) ? 'secondary' : 'grey',"small":""},on:{"click":function($event){return _vm.customerReceipt(item, true)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-receipt ")])]}}],null,true)},[_c('span',[_vm._v("Comprovante do usuário")])])]}},{key:"item.open",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{staticClass:"rounded-lg",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleTransaction(null, { item: item })}}},[_c('v-icon',[_vm._v(" mdi-open-in-app")])],1)]}}],null,true)})],1),_c('v-pagination',{staticClass:"py-4",attrs:{"color":"primary","length":_vm.pageCount,"total-visible":10,"circle":"","disabled":_vm.fetching},on:{"input":function($event){return _vm.getData()}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-dialog',{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('ManageDeposit',{attrs:{"init-tab":_vm.tab,"selected":_vm.selected},on:{"success":function($event){return _vm.getData()},"close":function($event){_vm.dialog = false}}}):_vm._e()],1),(_vm.loading)?_c('loader-hover'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }