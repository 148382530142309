<template>
  <v-card class="rounded-lg" color="accent">
    <section class="pa-4">
      <v-card-title class="px-0 pt-0">
        Depósito
        <v-spacer />
        <v-btn class="rounded-lg" icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-- navigation -->
      <v-slide-group
        v-model="tab"
        class="mb-4 foreground rounded-xl"
        mandatory
        show-arrows
      >
        <v-row no-gutters>
          <v-col v-for="(item, i) in tabs" :key="i">
            <v-slide-item v-slot="{ active, toggle }">
              <v-btn
                active-class="primary"
                :input-value="active"
                :plain="!active"
                depressed
                rounded
                small
                block
                @click="toggle"
              >
                {{ item }}
              </v-btn>
            </v-slide-item>
          </v-col>
        </v-row>
      </v-slide-group>

      <v-tabs-items v-model="tab" class="transparent mb-6">
        <!-- info -->
        <v-tab-item :value="0">
          <!-- resend transaction -->
          <v-list-item class="foreground rounded-lg mb-2">
            <v-list-item-content>
              <v-list-item-title>Reenviar transação</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                color="primary"
                depressed
                dark
                small
                @click="resendDialog = true"
              >
                Reenviar
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <!-- user info -->
          <v-list class="foreground pa-0 rounded-lg mb-2">
            <v-list-item>
              <v-list-item-action-text> Nome </v-list-item-action-text>

              <v-list-item-content>
                <input
                  v-model="selected.userName"
                  id="userName"
                  class="text-right primary_text--text field"
                  readonly
                  @focus="copy('userName')"
                />
              </v-list-item-content>

              <v-list-item-icon class="px-0">
                <v-icon color="secondary" small @click="copy('userName')">
                  mdi-content-copy
                </v-icon>
              </v-list-item-icon>
            </v-list-item>

            <v-list-item>
              <v-list-item-action-text> ID do usuário </v-list-item-action-text>

              <v-list-item-content>
                <input
                  v-model="selected.param1"
                  id="param1"
                  class="text-right primary_text--text field"
                  readonly
                  @focus="copy('param1')"
                />
              </v-list-item-content>

              <v-list-item-icon class="px-0">
                <v-icon color="secondary" small @click="copy('param1')">
                  mdi-content-copy
                </v-icon>
              </v-list-item-icon>
            </v-list-item>

            <v-list-item>
              <v-list-item-action-text> E-mail </v-list-item-action-text>

              <v-list-item-content>
                <input
                  v-model="selected.email"
                  class="text-right primary_text--text field"
                  id="email"
                  readonly
                  @focus="copy('email')"
                />
              </v-list-item-content>

              <v-list-item-icon class="px-0">
                <v-icon color="secondary" small @click="copy('email')">
                  mdi-content-copy
                </v-icon>
              </v-list-item-icon>
            </v-list-item>

            <v-list-item>
              <v-list-item-action-text> Documento </v-list-item-action-text>

              <v-list-item-content>
                <input
                  v-model="selected.document"
                  id="document"
                  class="text-right primary_text--text field"
                  readonly
                  @focus="copy('document')"
                />
              </v-list-item-content>

              <v-list-item-icon class="px-0">
                <v-icon color="secondary" small @click="copy('document')">
                  mdi-content-copy
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>

          <!-- transaction info -->
          <v-list class="foreground pa-0 rounded-lg mb-2">
            <v-list-item>
              <v-list-item-action-text> ID do Pedido </v-list-item-action-text>

              <v-list-item-content>
                <input
                  v-model="selected.orderId"
                  id="orderId"
                  class="text-right primary_text--text field"
                  readonly
                  @focus="copy('orderId')"
                />
              </v-list-item-content>

              <v-list-item-icon class="px-0">
                <v-icon color="secondary" small @click="copy('orderId')">
                  mdi-content-copy
                </v-icon>
              </v-list-item-icon>
            </v-list-item>

            <v-list-item>
              <v-list-item-action-text>
                Método de pagamento
              </v-list-item-action-text>

              <v-list-item-content>
                <v-list-item-title class="text-right">
                  {{ getMethod(selected.paymentMethod) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- card -->
            <v-list-item v-if="selected.card">
              <v-list-item-action-text> Nº Cartão </v-list-item-action-text>

              <v-list-item-content>
                <v-list-item-title class="text-right">
                  {{ selected.card.number }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- pix document -->
            <v-list-item v-if="selected.pix">
              <v-list-item-action-text>
                Titularidade PIX
              </v-list-item-action-text>

              <v-list-item-content>
                <v-list-item-title class="text-right">
                  {{ selected.pix.document }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- boleto -->
            <v-list-item v-if="selected.boleto">
              <v-list-item-action-text> Nosso Número </v-list-item-action-text>

              <v-list-item-content>
                <v-list-item-title class="text-right">
                  {{ selected.boleto.ourNumber }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- transfer bank -->
            <v-list-item v-if="selected.transfer">
              <v-list-item-action-text> Banco </v-list-item-action-text>

              <v-list-item-content>
                <v-list-item-title class="text-right text-body-2">
                  {{ selected.bankInfo.name }}
                </v-list-item-title>

                <v-list-item-subtitle class="text-right">
                  {{ selected.bankInfo.id }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-avatar size="32" class="px-0">
                <v-avatar size="32" color="foreground lighten-2">
                  <v-img :src="selected.bankInfo.urlIcon" />
                </v-avatar>
              </v-list-item-avatar>
            </v-list-item>

            <!-- transfer value -->
            <v-list-item v-if="selected.transfer">
              <v-list-item-action-text>
                Valor de Transferência
              </v-list-item-action-text>

              <v-list-item-content>
                <v-list-item-title class="text-right">
                  R$ {{ moneyMask(selected.value) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- picpay user -->
            <v-list-item v-if="selected.picpay">
              <v-list-item-action-text>
                Usuário PicPay
              </v-list-item-action-text>

              <v-list-item-content>
                <v-list-item-title class="text-right">
                  {{ selected.picpay.username }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- deposit value -->
            <v-list-item>
              <v-list-item-action-text>
                Valor do Depósito
              </v-list-item-action-text>

              <v-list-item-content>
                <v-list-item-title class="text-right">
                  R$ {{ moneyMask(selected.convertedValue) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <!--status -->
          <v-list-item class="foreground rounded-lg mb-2">
            <v-list-item-content>
              <v-list-item-title> Status da transação</v-list-item-title>
              <v-list-item-title>
                <span
                  :class="`${
                    getStatus(selected.transactionStatusId).color
                  }--text`"
                >
                  {{ getStatus(selected.transactionStatusId).label }}
                </span>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :color="getStatus(form.status).color"
                    v-bind="attrs"
                    v-on="on"
                    depressed
                    dark
                    small
                  >
                    {{ getStatus(form.status).label }}

                    <v-icon right> mdi-chevron-down </v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, index) in status"
                    :key="index"
                    @click="form.status = item.value"
                  >
                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-tab-item>

        <!-- receipt -->
        <v-tab-item :value="1">
          <v-list-item class="foreground mb-2 rounded-lg">
            <v-list-item-icon class="mr-3">
              <v-icon color="primary">mdi-receipt</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Comprovante do analista </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-row no-gutters>
                <v-btn
                  v-if="receipts.analyst"
                  class="mr-2 rounded-lg"
                  target="_blank"
                  :href="receipts.analyst"
                  small
                  icon
                >
                  <v-icon>mdi-image-search-outline</v-icon>
                </v-btn>

                <SendReceipt
                  :transaction="selected"
                  @success="getUrlReceipt($event)"
                />
              </v-row>
            </v-list-item-action>
          </v-list-item>

          <v-list-item class="foreground rounded-lg">
            <v-list-item-icon class="mr-3">
              <v-icon color="secondary">mdi-receipt</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title> Comprovante do cliente </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                v-if="receipts.customer"
                class="mr-2 rounded-lg"
                target="_blank"
                :href="receipts.customer"
                small
                icon
              >
                <v-icon>mdi-image-search-outline</v-icon>
              </v-btn>

              <span v-else class="secondary_text--text"> aguardando </span>
            </v-list-item-action>
          </v-list-item>
        </v-tab-item>

        <!-- logs -->
        <v-tab-item :value="2" v-if="selected.logs" :disabled="selected.logs.length < 1">
          <v-list-item
            v-for="(item, index) of _logs"
            :key="index"
            class="foreground rounded-lg mb-1"
          >
            <v-list-item-icon class="mr-3">
              <v-icon v-if="!index" color="white" v-text="'mdi-sort-clock-descending-outline'"/>
              <v-icon v-else color="grey" v-text="'mdi-sort-clock-descending-outline'"/>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.description }}</v-list-item-title>
              <v-list-item-subtitle>
                <span
                  class="caption"
                  title="(UTC-3) Horário de Brasília"
                  v-text="formatDateToLocale(item.createdAt)"
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-tab-item>
      </v-tabs-items>

      <v-row no-gutters>
        <v-spacer />

        <v-btn plain @click="close()"> Cancelar </v-btn>

        <v-btn color="primary" @click="submit()"> Confirmar </v-btn>
      </v-row>
    </section>

    <!-- dialog resend -->
    <v-dialog v-model="resendDialog" max-width="400">
      <v-card v-if="resendDialog" color="accent">
        <v-card-title>Reenviar transação</v-card-title>

        <v-card-text class="text-body-1">
          Deseja mesmo
          <span class="primary--text font-weight-bold">reenviar</span> esta
          transação?
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn plain @click="resendDialog = false"> Cancelar </v-btn>

          <v-btn color="primary" @click="resend()">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loader-hover v-if="loading" />
  </v-card>
</template>

<script>
import {transactionStatus, resendTransaction} from "@/services/transaction";
import {displayAlert, moneyMask, formatDateToLocale} from "@/utils";
import SendReceipt from "./SendReceipt";

export default {
  data() {
    return {
      loading: false,
      resendDialog: false,
      tabs: ["Informações", "Comprovantes", "Logs"],
      tab: 0,
      status: [
        {
          value: 1,
          label: "Aguardando",
        },
        {
          value: 2,
          label: "Aprovado",
        },
        {
          value: 3,
          label: "Recusado",
        },
        {
          value: 4,
          label: "Cancelado",
        },
        {
          value: 5,
          label: "Estornado",
        },
      ],
      form: {
        status: 0,
        motive: "",
      },
      receipts: {
        analyst: null,
        customer: null,
      },
    };
  },

  props: {
    selected: {
      type: Object,
      default: {},
    },
    initTab: {
      type: Number,
      default: () => 0
    }
  },

  components: {
    SendReceipt,
  },

  computed: {
    _logs: function () {
      return (this.selected.logs || []).reverse()
    }
  },

  beforeMount() {
    this.form.status = this.selected.transactionStatusId;

    this.receipts = {
      analyst: this.selected.urlReceiptAdmin,
      customer:
        this.selected[this.selected.paymentMethod.toLowerCase()].urlReceipt ||
        null,
    };
  },

  mounted() {
    this.tab = this.initTab || 0
  },

  methods: {
    async submit() {
      try {
        this.loading = true;

        const payload = {
          transactionId: this.selected.id,
          status: this.form.status,
          motive: this.form.motive,
        };

        await transactionStatus(payload).then(() => {
          this.displayAlert("Transação atualizada com sucesso");
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    // resend transaction
    async resend() {
      try {
        this.loading = true;

        const payload = {
          transactionId: this.selected.id,
        };

        await resendTransaction(payload).then(() => {
          this.displayAlert("Transação reenviada com sucesso");
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    getUrlReceipt(data) {
      this.receipts.analyst = data;
    },

    // return label of method
    getMethod(value) {
      switch (value) {
        case "TRANSFER":
          return "Transferência";
        case "CARD":
          return "Cartão";
        case "BOLETO":
          return "Boleto";
        case "PIX":
          return "Pix";
        case "PICPAY":
          return "PicPay";
        case "WITHDRAW":
          return "Saque";
        default:
          return "";
      }
    },

    getStatus(value) {
      switch (value) {
        case 1:
          return {
            label: "Aguardando",
            color: `amber`,
          };
        case 2:
          return {
            label: "Aprovado",
            color: "green",
          };
        case 3:
          return {
            label: "Recusado",
            color: "red",
          };
        case 4:
          return {
            label: "Cancelado",
            color: "grey",
          };
        case 5:
          return {
            label: "Estornado",
            color: "purple",
          };
        default:
          return {
            label: "Carregando",
            color: "primary_text--text",
          };
      }
    },

    // copy do clipboard
    copy(value) {
      var textBox = document.getElementById(value);
      textBox.select();
      document.execCommand("copy");
    },

    close() {
      this.$emit("close");
    },

    displayAlert,

    moneyMask,

    formatDateToLocale
  },
};
</script>

<style lang="scss" scoped>
.field:focus {
  outline: none !important;
  border: 0px;
}
</style>
