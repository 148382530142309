<template>
  <v-container class="py-4" fluid>
    <v-row class="mx-2 mb-4 text-h6 font-weight-black align-center" no-gutters>
      <v-icon class="mr-3">mdi-arrow-down-thick</v-icon>
      Depósitos
    </v-row>

    <sub-navigation :routes="routes" class="mb-4" />

    <Filters
      class="mb-2"
      :dataFilters="require('@/assets/filters/deposit.json')"
      @apply-filter="applyFilter"
    />

    <section>
      <!-- summary and filter status  -->
      <v-row class="pa-3">
        <v-chip-group v-model="status" class="mb-2" column @change="getData()">
          <v-chip
            v-for="(item, i) in statusFilter"
            color="transparent"
            class="mr-1"
            :active-class="`${item.color}--text`"
            :key="i"
            :value="item.value"
            small
          >
            <v-icon :color="item.color" left small> mdi-square-rounded</v-icon>

            <span class="text-caption">
              {{ item.label }}
            </span>
          </v-chip>
        </v-chip-group>

        <v-spacer />

          <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          class="align-self-center ma-3"
          color="grey"
          :loading="fetching"
          :disabled="fetching"
          small
          icon
          @click="getData()"
        >
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
      </v-row>

      <!-- table -->
      <v-card class="rounded-lg" outlined>
        <v-data-table
          class="accent pointer-hand"
          :headers="headers"
          :items="transactions"
          :items-per-page="itemsPerPage"
          :mobile-breakpoint="0"
          :item-class="rowClasses"
          :custom-sort="customSort"
          hide-default-footer
          @dblclick:row="handleTransaction"
        >
          <!-- orderId -->
          <template v-slot:[`item.orderId`]="{ item }">
            <div class="d-flex justify-start align-center">
              <span v-text="item.orderId" />

              <v-tooltip
                v-if="
                  item.logs &&
                    item.logs.length &&
                    getPixLogStatusCounts(item)['notAwaiting']
                "
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    class="ms-2 mb-4"
                    v-bind="attrs"
                    v-on="on"
                    size="20px"
                    :style="{ border: '1px solid red' }"
                    @click="handleTransaction(null, { item, tab: 2 })"
                  >
                    <span
                      class="primary_text--text"
                      v-text="`+${getPixLogStatusCounts(item)['notAwaiting']}`"
                      :style="{ fontSize: '10px' }"
                    />
                  </v-avatar>
                </template>
                <span
                  class="caption"
                  v-text="getPixLogAdditionalPaymentsText(item)"
                />
              </v-tooltip>
            </div>
          </template>

          <!-- method -->
          <template v-slot:[`item.paymentMethod`]="{ item }">
            <span>{{ getMethod(item.paymentMethod) }}</span>
          </template>

          <!-- user -->
          <template v-slot:[`item.user`]="{ item }">
            <span class="pt-1">
              <p class="mb-0 single-line">
                {{ item.userName }}
              </p>

              <p class="mb-0 text-caption single-line">{{ item.param1 }}</p>
            </span>
          </template>

          <!-- email and document -->
          <template v-slot:[`item.email`]="{ item }">
            <span class="pt-1">
              <p
                class="mb-0 single-line"
                :style="
                  `max-width: ${$vuetify.breakpoint.mdAndUp ? '14vw' : '48vw'}`
                "
              >
                {{ item.email }}
              </p>

              <p class="mb-0 text-caption single-line">{{ item.document }}</p>
            </span>
          </template>

          <!-- value -->
          <template v-slot:[`item.convertedValue`]="{ item }">
            R$ {{ moneyMask(item.convertedValue) }}
          </template>

          <!-- created -->
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>

          <!-- updated -->
          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ formatDate(item.updatedAt) }}
          </template>

          <!-- requestStatus -->
          <template v-slot:[`item.requestStatus`]="{ item }">
            <v-chip :color="getColorByRequestStatus(item.requestStatus)">
              {{ item.requestStatus || "- -" }}
            </v-chip>
          </template>

          <!-- credited -->
          <template v-slot:[`item.credited`]="{ item }">
            <v-icon
              v-if="[0, 1, 2, 3].includes(item.credited)"
              :color="creditedList[item.credited].color"
              :title="creditedList[item.credited].label"
            >
              {{ creditedList[item.credited].icon }}
            </v-icon>
            <v-chip v-else color="grey">
              {{ "- -" }}
            </v-chip>
          </template>

          <!-- additional data -->
          <template v-slot:[`item.additional`]="{ item }">
            <!-- card -->
            <span v-if="item.card">
              <p class="mb-0 single-line">
                {{ item.card.number }}
              </p>
              <p class="mb-0 text-caption">Cartão</p>
            </span>

            <!-- pix -->
            <span v-else-if="item.pix">
              <p class="mb-0 single-line">
                {{ item.pix.document }}
              </p>
              <p class="mb-0 text-caption">Documento PIX</p>
            </span>

            <!-- boleto -->
            <span v-else-if="item.boleto">
              <p class="mb-0 single-line">
                {{ item.boleto.ourNumber }}
              </p>
              <p class="mb-0 text-caption">Nosso número</p>
            </span>

            <!-- transfer -->
            <span v-else-if="item.transfer">
              <p class="mb-0 single-line">
                R$ {{ moneyMask(item.transfer.transferValue) }}
              </p>
              <p class="mb-0 text-caption">Transferência</p>
            </span>

            <!-- picpay -->
            <span v-else-if="item.picpay">
              <p class="mb-0 single-line">
                {{ item.picpay.username }}
              </p>
              <p class="mb-0 text-caption">Usuário PicPay</p>
            </span>

            <!-- empty -->
            <span v-else> ~ </span>
          </template>

          <!-- receipt -->
          <template v-slot:[`item.receipt`]="{ item }">
            <v-tooltip color="grey darken-4" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  :color="analystReceipt(item) ? 'secondary' : 'grey'"
                  small
                  @click="analystReceipt(item, true)"
                >
                  mdi-receipt
                </v-icon>
              </template>

              <span>Comprovante do analista</span>
            </v-tooltip>

            <v-tooltip color="grey darken-4" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  :color="customerReceipt(item) ? 'secondary' : 'grey'"
                  small
                  @click="customerReceipt(item, true)"
                >
                  mdi-receipt
                </v-icon>
              </template>

              <span>Comprovante do usuário</span>
            </v-tooltip>
          </template>

          <!-- open -->
          <template v-slot:[`item.open`]="{ item }">
            <v-btn
              class="rounded-lg"
              icon
              small
              @click="handleTransaction(null, { item })"
            >
              <v-icon> mdi-open-in-app</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>

      <!-- pagination -->
      <v-pagination
        v-model="page"
        class="py-4"
        color="primary"
        :length="pageCount"
        :total-visible="10"
        circle
        :disabled="fetching"
        @input="getData()"
      />
    </section>

    <v-dialog v-model="dialog" max-width="600" persistent>
      <ManageDeposit
        v-if="dialog"
        :init-tab="tab"
        :selected="selected"
        @success="getData()"
        @close="dialog = false"
      />
    </v-dialog>

    <loader-hover v-if="loading" />
  </v-container>
</template>

<script>
import { getTransactions, getAccountBanks } from "@/services/transaction";
import { displayAlert, moneyMask, formatDate } from "@/utils";
import ManageDeposit from "@/components/transactions/ManageDeposit";
import Filters from "@/components/filters/Filters";
import socket from "../../services/socket";

export default {
  data() {
    return {
      loading: true,
      fetching: false,
      requested: false,
      lastRequest: new Date().getTime(),
      interval: null,
      dialog: false,
      tab: 0,
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      sort: "createdAt,desc",
      routes: [
        { label: "Todos", path: "/deposits" },
        { label: "Cartão", path: "/deposits/card" },
        { label: "Pix", path: "/deposits/pix" },
        { label: "Boleto", path: "/deposits/boleto" },
        { label: "Transferência", path: "/deposits/transfer" },
        { label: "PicPay", path: "/deposits/picpay" },
      ],
      statusFilter: [
        {
          label: "Aguardando",
          color: "amber",
          value: 1,
        },
        {
          label: "Aprovado",
          color: "green",
          value: 2,
        },
        {
          label: "Rejeitado",
          color: "red",
          value: 3,
        },
        {
          label: "Cancelado",
          color: "grey",
          value: 4,
        },
        {
          label: "Estornado",
          color: "purple",
          value: 5,
        },
      ],
      headers: [
        { text: "ID", value: "orderId" },
        { text: "Tipo", value: "paymentMethod" },
        { text: "Usuário", value: "user" },
        { text: "E-mail e documento", value: "email" },
        { text: "Valor", value: "convertedValue" },
        { text: "Criação", value: "createdAt" },
        { text: "Atualização", value: "updatedAt" },
        { text: "Adicional", value: "additional", width: 182, sortable: false },
        {
          text: "Request",
          value: "requestStatus",
          sortable: false,
          align: "center",
        },
        { text: "creditado", value: "credited", align: "center" },
        {
          text: "Comprov.",
          value: "receipt",
          align: "center",
          sortable: false,
        },
        { text: "Abrir", value: "open", align: "center", sortable: false },
      ],
      transactions: [],
      filter: [],
      status: "",
      accountBanks: [],
      selected: null,
      creditedList: [
        { index: 0, icon: "", label: "Aguardando Verificação", color: "white" },
        {
          index: 1,
          icon: "mdi-check-decagram-outline",
          label: "Creditado",
          color: "green",
        },
        {
          index: 2,
          icon: "mdi-decagram",
          label: "Processando",
          color: "yellow",
        },
        {
          index: 3,
          icon: "mdi-alert-decagram-outline",
          label: "Erro",
          color: "red",
        },
      ],
    };
  },

  components: {
    ManageDeposit,
    Filters,
  },

  beforeMount() {
    this.getData();
    this.getAccountBanks();
  },

  created() {
    this.interval = setInterval(() => {
      if (!this.requested || this.lastRequest + 10000 > new Date().getTime()) {
        return;
      }

      this.getData();
    }, 1000);

    socket.on("transaction", () => {
      this.requested = true;
    });
  },

  beforeDestroy() {
    socket.off("transaction");
    clearInterval(this.interval);
  },

  watch: {
    ["$route"]() {
      this.loading = true;
      this.page = 1;

      this.getData();
    },
  },

  methods: {
    async getData() {
      try {
        this.fetching = true;
        this.requested = false;
        this.lastRequest = new Date().getTime();

        const payload = {
          page: String(this.page - 1),
          size: this.itemsPerPage,
          sort: this.sort,
          paymentMethod: this.$route.params.method
            ? this.$route.params.method.toUpperCase()
            : "TRANSFER,CARD,BOLETO,PIX,PICPAY",
          transactionStatusId: this.status,
          ...this.filter,
        };

        await getTransactions(payload).then((res) => {
          this.transactions = res.body.content;
          this.pageCount = res.body.totalPages;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.fetching = false;
        this.loading = false;
      }
    },

    // get account bank list
    async getAccountBanks() {
      try {
        await getAccountBanks().then((res) => {
          this.accountBanks = res.body;
        });
      } catch (err) {
        console.log(err);
      }
    },

    // open transaction
    handleTransaction(event, { item, tab }) {
      if (!item.paymentMethod) return;

      const bank = item.transfer
        ? this.accountBanks.find((e) => e.id === item.transfer.bankAccount).bank
        : null;

      this.selected = {
        ...item,
        bankInfo: bank,
      };

      this.dialog = true;
      this.tab = tab;
    },

    // return label of method
    getMethod(value) {
      switch (value) {
        case "TRANSFER":
          return "Transf.";
        case "CARD":
          return "Cartão";
        case "BOLETO":
          return "Boleto";
        case "PIX":
          return "Pix";
        case "PICPAY":
          return "PicPay";
        case "WITHDRAW":
          return "Saque";
        default:
          return "";
      }
    },

    // return label of status
    getStatus(value) {
      switch (value) {
        case 1:
          return {
            label: "Aguardando",
            color: "amber",
            icon: "mdi-timer-sand",
          };
        case 2:
          return {
            label: "Aprovado",
            color: "green",
            icon: "mdi-checkbox-marked-circle-outline",
          };
        case 3:
          return {
            label: "Recusado",
            color: "red",
            icon: "mdi-close",
          };
        case 4:
          return {
            label: "Cancelado",
            color: "grey",
            icon: "mdi-cancel",
          };
        case 5:
          return {
            label: "Estornado",
            color: "purple",
            icon: "mdi-undo",
          };
        default:
          return {
            label: "",
            color: "",
            icon: "",
          };
      }
    },

    // return row status color
    rowClasses(item) {
      return `${this.getStatus(item.transactionStatusId).color}--text text--${
        this.$vuetify.theme.dark ? "lighten-2" : "darken-2"
      }`;
    },

    // apply filter
    applyFilter(value) {
      this.loading = true;
      this.filter = value;
      this.page = 1;

      this.getData();
    },

    // sort table
    customSort(items, sortBy, sortDesc) {
      let value = sortBy[0];
      let sort = "";

      if (value) sort = value + "," + (sortDesc[0] ? "desc" : "asc");
      else sort = "createdAt,desc";

      if (sort !== this.sort) {
        this.sort = sort;
        this.getData();
      }

      return items;
    },

    // open receipt customer
    customerReceipt(data, open = false) {
      const method = data.paymentMethod.toLowerCase();

      if (data[method] && data[method].urlReceipt) {
        if (open) window.open(data[method].urlReceipt, "_blank").focus();
        return true;
      }

      return false;
    },

    // receipt analyst
    analystReceipt(data, open = false) {
      if (data.urlReceiptAdmin) {
        if (open) window.open(data.urlReceiptAdmin, "_blank").focus();
        return true;
      }
      return false;
    },

    getPixLogStatusCounts(item) {
      const descriptions = {
        diffValue: "Different values",
        invalidMethod: "invalid payment method",
        notAwaiting: "Transaction not awaiting",
        approved: "Transaction is 'APPROVED' by SANTANDER script",
        manual: "Transaction changed status.",
        timeout: "Refused by timeout",
      };
      const keys = Object.keys(descriptions);

      const statusCounts = {};
      keys.forEach((key) => (statusCounts[key] = 0));

      item.logs.forEach((log) => {
        let type;
        keys.forEach((key) => {
          if (log.description.includes(descriptions[key])) return (type = key);
        });
        statusCounts[type]++;
      });

      return statusCounts;
    },

    getPixLogAdditionalPaymentsText(item) {
      const count = this.getPixLogStatusCounts(item)["notAwaiting"];
      const isMultiple = count > 1;
      return `+${count} ${
        isMultiple ? "recebimentos adicionais." : "recebimento adicional."
      }`;
    },

    getColorByRequestStatus(requestStatus) {
      if (!requestStatus) return "grey";

      const status = requestStatus.toString();

      if (status.startsWith("2")) return "green";
      if (status.startsWith("3")) return "blue";
      if (status.startsWith("4") || status.startsWith("5")) return "red";

      return "grey";
    },

    displayAlert,

    moneyMask,

    formatDate,
  },
};
</script>
