<template>
  <section>
    <v-btn class="rounded-lg" icon small @click.prevent="onButtonClick()">
      <v-icon>mdi-paperclip</v-icon>
    </v-btn>

    <input
      ref="uploader"
      class="d-none"
      type="file"
      accept="image/png, image/jpeg, image/bmp, image/jpg, .pdf"
      @change="onFileChanged"
    />

    <loader-hover v-if="loading" />
  </section>
</template>

<script>
import { sendReceipt } from "@/services/transaction";
import { displayAlert } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      file: null,
    };
  },

  props: {
    transaction: {
      type: Object,
      default: {},
    },
  },

  methods: {
    async submit() {
      if (!this.file) return;

      try {
        this.loading = true;

        const formData = new FormData();
        formData.append("file", this.file);

        const headers = {
          ref: this.transaction.id,
        };

        await sendReceipt(formData, headers).then((res) => {
          this.displayAlert("Comprovante enviado com sucesso");
          this.$emit("success", res.body.urlReceiptAdmin);
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    onButtonClick() {
      window.addEventListener(
        "focus",
        () => {
          /* */
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },

    onFileChanged(e) {
      const file = e.target.files[0];
      this.file = file;

      this.submit();
    },

    displayAlert,
  },
};
</script>

<style>
</style>