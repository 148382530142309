import { request } from "./index";

const isDefined = (value) => ![null, undefined].includes(value);

export const getTransactions = (data) =>
  request(
    "get",
    `backoffice/transaction/list?page=${data.page}&size=${data.size}&sort=${data.sort}&id=${data.id}&orderId=${data.orderId}&email=${data.email}&userName=${data.userName}&param1=${data.param1}&document=${data.document}&paymentMethod=${data.paymentMethod}&createdAt=${data.createdAt}&transactionStatusId=${data.transactionStatusId}&card.number=${data.cardNumber}&pix.document=${data.pixDocument}&boleto.ourNumber=${data.boletoOurNumber}&transfer.transferValue=${data.transferValue}&picpay.username=${data.picpayUsername}${isDefined(data.credited) ? '&credited=' + (data.credited ? 1 : 0) : ''}`
  );

export const transactionStatus = (data) =>
  request("post", "/backoffice/transaction/status", data);

export const getBanks = () => request("get", `/banks`);

export const getAccountBanks = () => request("get", "/banks/account");

export const getWithdrawInfo = () =>
  request("get", "/withdraw/balance/awaiting");

export const transactionSome = (data) =>
  request("get", `/transaction/some?document=${data}`);

export const sendReceipt = (formData, headers) =>
  request("post", "/transaction/append/admin", formData, headers);

export const resendTransaction = (data) =>
  request("post", "/backoffice/transaction/resend", data);
